// @flow

import React from "react";
import { Link } from "gatsby";
import { Flex, Box, Text } from "rebass";

import Layout from "../components/layout";
import MainImage from "../components/mainImage";
import SEO from "../components/seo";
import SnowIcon from "../components/snowIcon";
import RidiIcon from "../components/ridiIcon";
import MantaIcon from "../components/mantaIcon";
import SsmIcon from "../components/ssmIcon";
import Comment from "../components/Comment";
import LinkedinIcon from "../components/linkedinIcon";
import EmailIcon from "../components/emailIcon";

const CAREER_OVERVIEW_TEXTS = [
  "디지털 제품을 만듭니다.",
]

//https://apps.apple.com/kr/app/manta-unlimited-comics/id1536116642
//https://play.google.com/store/apps/details?id=net.manta.comic&hl=ko&gl=US

const RIDI_TEXTS = [
  "<b>Manta Product Lead (2022. 02 ~)</b>",
  "현재 리디에서는 글로벌 웹툰 구독 서비스인 <a href='https://manta.onelink.me/k8dS' target='_blank'>만타(Manta)</a>를 만드는 <b>Manta Product</b> 팀을 리딩하고 있습니다.\n"+
  "2020년에 11월에 출시된 만타는 영어 및 스페인어로 서비스되며, 글로벌로 1,300만 이상의 다운로드로 13개 국가에서 Comics App 1위를 기록했습니다.\n\n" +
  "🗒️ <a href='https://ridicorp.com/story/pr-manta-product-team-leader-interview/' target='_blank'>[인터뷰]‘만타(Manta)’ 제품팀장 이윤재님</a><br/>",
  "만타에서는 제품 방향성 및 목표 수립과 실행, 주요 프로젝트 전반을 관리 및 리딩하고 있습니다. 만타에서 진행한 주요 프로젝트는 다음과 같습니다.",
  "<b>1. 국가/언어의 확장과 글로벌 최적화</b>\n"
  +"만타는 영어와 스페인어로 서비스되고 있으며, 앞으로도 다양한 언어 및 국가에서 글로벌 서비스를 제공하는 것을 목표로 합니다. 이에 맞춰 콘텐츠 및 서비스를 다국어를 지원할 수 있는 환경 및 효율적인 운영을 가능하게 하는 구조를 만들었으며, 국가 및 환경에 맞는 경험을 제공하기 위해 다양한 실험을 통해 최적화를 진행하고 있습니다.",
  "<b>2. 비즈니스 모델 개선</b>\n"
  +"구독 모델은 다양한 콘텐츠 비즈니스에서 흔히 쓰이고 있지만, 콘텐츠의 특성과 국가에 따라서 전환율과 리텐션의 차이가 크게 발생합니다. 만타에서는 구독/멤버십 형태의 비즈니스 모델을 효과적으로 서비스하기 위해  장기 구독, 구독 가격 조정, 전환율, 광고 수익화 등의 프로젝트를 진행했습니다.",
  "<b>3. 리텐션을 높이기 위한 작품의 탐색 및 추천의 개선</b>\n"
  +"콘텐츠 서비스의 리텐션은 고객이 lock-in된 콘텐츠의 양과 높은 상관성이 있습니다. 사용자가 취향에 맞는 작품을 쉽게 발견하고 감상할 수 있게 만드는 탐색 과정의 개선을 꾸준히 진행했으며, 이를 위한 제품의 방향성 및 로드맵을 수립했습니다.",
  "<b>리디 Product Manager (2020. 08 ~ 2022. 01)</b>",
  "리디에서는 리디 서비스의 앱/웹을 담당하는 Product Manager로 근무했습니다.\n" +
  "10년동안 전자책을 중심으로 성장한 <a href='https://manta.onelink.me/k8dS' target='_blank'>리디</a>는 웹툰, 웹소설과 전자책 등의 다양한 디지털 콘텐츠를 고객에게 전달하고 있습니다.",
  "리디에서는 제품 프로젝트에 대한 의사결정 및 협업을 과정을 주도했으며, 이전 engineer의 경험을 기반으로 technical project를 주로 진행했습니다. 진행한 주요 프로젝트는 아래와 같습니다.",
  "<b>1. 장르 확장을 위한 서비스 리뉴얼</b>\n"
  +"2009년부터 전자책을 중심으로 시작된 리디는 2020년부터는 본격적으로 만화, 웹소설, 웹툰으로 확장을 시작했습니다. 다양한 카테고리의 콘텐츠를 서비스하게 된 만큼, 고객이 작품을 찾아다니는 탐색 지면도 이에 맞추어서 대대적인 개편이 필요했습니다.\n" 
  +"이 과정에서 전체 서비스의 구조와 각 장르/카테고리에 맞는 탐색 지면의 방향성을 수립하는 과정에 참여하였고, 특히 웹툰을 중심으로 한 콘텐츠 라이프사이클을 관리하는 제품의 전체 사이클을 개선하는 프로젝트를 진행했습니다.",
  "<b>2. 서비스 기술 기반의 변경</b>\n"
  +"기존에 뷰어의 기능이 중심이던 리디북스 앱을 작품의 탐색 및 구매의 기능을 포함하는 과정에서, 효율적인 개발 및 개선을 위해 크로스 플랫폼으로 개발할 수 있도록 앱/웹의 기술 기반의 변경과 React Native 도입을 주도했습니다. 이 과정에서 초기 환경 세팅 및 개발을 함께 진행하였으며, 앱 내 연재형 작품을 추가하는 프로젝트를 진행하였습니다.\n"
  +"(▶️<a href=https://www.youtube.com/watch?v=scZI19SE0_4' target='_blank'>[인터뷰]네이티브 앱, 리액트 네이티브 앱 으로!</a>)",
  "<b>3. 쿠폰 및 프로모션 시스템의 개선</b>\n"
  +"단건 판매를 중심으로한 콘텐츠 플랫폼인 만큼 프로모션과 쿠폰, 포인트 등의 구매와 전환을 촉진하는 장치들의 개선은 매출에 직접적인 영향을 주는 feature입니다. 오랜 레거시로 파편화되어있던 쿠폰/포인트 시스템을 일원화하고, 이를 활용한 프로모션을 쉽게 진행할 수 있도록 하는 운영/관리 시스템의 구축을 주도했습니다."
];

const SNOW_TEXTS = [
  "SNOW에서 서비스하는 글로벌 영어 회화 앱 <b>Cake</b> 팀에서 근무했습니다.\n<b>Cake</b>는 50개 이상 국가에서 1,500만 이상의 글로벌 사용자에게 사랑받고 있습니다.",
  "‘씀'을 함께 만들던 10B 팀이 acquisition 되어 <b>Software Engineer</b>로 합류했고, Cake studio에서 React Native을 통한 앱 개발을 담당하였습니다. 빠르게 성장하는 글로벌 서비스를 만들기 위해 크로스 플랫폼 앱을 개발하고, A/B 테스트의 구축 및 도입으로 글로벌에서 다수의 실험과 검증을 진행할 수 있는 기반을 만들었습니다. 진행한 주요 프로잭트는 아래와 같습니다.",
  "<b>A/B 테스트 환경 구축</b>\n"
  +"국내에서 글로벌 서비스를 만들기 위해서는 빠른 실험으로 사용자의 반응을 관찰하는 것이 필요했습니다. 생산성이 높은 크로스 플랫폼 개발의 장점을 살려 쉽게 실험을 실행하고 결과를 모니터링 할 수 있는 환경을 도입하고 다수의 실험을 진행했습니다.",
  "<b>사용자 음성 보정을 위한 native module 개발</b>\n"
  +"Cake는 영어 회화 앱으로 사용자의 발음을 분석하는 기능이 있습니다. 이때 앱에서 사용자의 음성을 받고 보정하기 위해 음성 입력을 react native로 전달하는 과정을 처리하기 위한 iOS/Android native 모듈의 개발을 진행했으며, 이를 바탕으로 이후 오디오 학습 기능인 ‘Speak’ 기능의 개발을 주도했습니다.",
  "<b>앱 다국어 대응</b>\n"
  +"하나의 UX/UI를 기반으로 10개 이상의 언어로 서비스될 수 있도록 앱 내 다국어 적용 방식과 아랍어, 일본어 등의 서로 다른 포멧을 가진 언어의 지원할 수 있는 인터페이스 관리 구조를 적용했습니다.",
  "<a href='https://cake.day/' target='_blank'>Cake 소개</a><br/><a href='https://apps.apple.com/kr/app/cake-%EC%BC%80%EC%9D%B4%ED%81%AC-%EC%98%81%EC%96%B4%ED%9A%8C%ED%99%94/id1350420987' target='_blank'>Cake iOS 🔗</a><br/><a href='https://play.google.com/store/apps/details?id=me.mycake&hl=ko' target='_blank'>Cake Android 🔗</a>",
];



const SSM_TEXTS = [
  "10B는 <b>‘씀 : 일상적 글쓰기’</b>를 서비스 했습니다.\n\n<b>씀</b>은 2016년에 Google Play 올해의 앱, App Store 올해의 최고작으로 선정되었습니다. 100만 명의 사용자로부터 500만 편 이상의 글이 작성되었습니다.",
  "씀은 '글쓰기'를 중심으로 시작하여, 구독과 작품 발행을 중심으로 발전한 서비스입니다. 씀의 기능적인 부분은 크게 '글쓰기', '작품 발행', '작가 구독', '감상' 이었으며, 비즈니스적 장치로 '작품 구매', '종이책 제작(POD 서비스)', '출판'이 있었습니다.",
  "<b>[활자 중심의 인터페이스]</b> 씀의 인터페이스는 종이와 활자의 메타포에서 많은 부분을 차용했습니다. 원고지가 주는 질감과 색감, 활자로 찍어낸 글씨들이 주는 느낌, 책과 종이에서 느껴지는 영역의 구분감을 활용하기 위해 대부분의 인터페이스에서 흑백의 색감을 중심으로 활용하였고, 아이콘의 사용을 최소화하면서도 인터렉션에 대한 인지를 높히기 위해 서비스 전체에서 영문자를 사용하지 않으면서도 되도록이면 두글자 이하의 단어로 버튼이나 기능을 지칭했습니다.",
  "<b>[글감]</b> 보다 손쉽게 글쓰기를 시작할 수 있도록 단어로 된 글감과 영감을 주는 인용구를 선정하여 매일 오전/오후 7시에 전달하였습니다. 정해진 시간에 전달되는 글감으로 유저들의 습관을 만들고, 최근 업데이트된 글감에 관심을 가지게 만들어 활성 사용자가 특정 시간에 집중적으로 활동하게 만들었습니다.",
  "<b>[작품의 발행]</b> '모음'이란 이름으로 부르는 '작품 발행' 기능은, 1000자 내외의 짧은 글로만 적혀지던 씀의 글들을 하나의 작품 아래에 엮어서 내부 지면에 공개하고 댓글 등의 관심을 받을 수 있는 기능이었습니다. 이 기능은 짧은 책을 구성하듯 표지와 목차, 페이지, 후기 등을 구성할 수 있었고 시와 에세이부터 소설까지 다양한 형식의 작품이 올라오고 유저들이 서로 교감하고 반응하는 생태계를 구성했습니다.",
  "<b>[종이책 제작 서비스]</b> 내부 '모음' 기능으로 만들어진 글을 소책자로 엮어서 POD(Print on demand)로 인쇄하고 배송받을 수 있는 기능을 제공했습니다.",
  "<b>[작품 판매 및 출판]</b> 기고받은 작품, 또는 외부 등단한 작가의 시/소설 단편 작품을 디지털 콘텐츠로 판매했으며, 또한 씀에서 활동하는 작가를 중심으로 <오롯이, 혼자>, <최대 흐림>, <시들지 않기 위해 피지 않을 것>의 3종의 책을 발행하였으며, 이외에도 타 출판 브랜드와의 협업으로 씀의 유저들의 글을 기고하거나 출판사와 연결해주는 역할을 했습니다.",
  "씀을 만드는 10B는 2018년 말에 SNOW로 합류하였습니다.",
  "<a href='https://apps.apple.com/kr/app/%EC%94%80-%EC%9D%BC%EC%83%81%EC%A0%81-%EA%B8%80%EC%93%B0%EA%B8%B0/id1118780107' target='_blank'>씀 iOS 🔗</a><br/><a href='https://play.google.com/store/apps/details?id=com.projectm.ezbrother.ssm&hl=ko' target='_blank'>씀 Android 🔗</a>",
];

const EDU_TEXTS = [
  "시스템 공학을 전공하고 컴퓨터 공학과 산업 디자인을 부전공했습니다.\n\n Computer science & Programming, HCI, UX 디자인을 주로 공부했으며 공학적 방법론 및 분석을 배웠습니다.",
  "졸업 전에 창업을 하기 위해 2016년부터 휴학을 하였고, 2020년에 중퇴했습니다.",
];

const SIDE_TEXTS_1 = [
  "<b>놐놐</b>은 스토리와 주제를 가지고 화상으로 상대를 만나는 랜선 소개팅입니다. 네 명의 상대와 15분씩 이야기를 나누고 나에게 맞는 사람을 선택합니다.",
  "제품/비즈니스 기획에 참여하고, 웹 디자인 및 개발 전반을 담당하였습니다.",
  "<a href='https://www.facebook.com/knockknockdate/' target='_blank'>놐놐 🔗</a> (서비스 종료)",
];

const SIDE_TEXTS_2 = [
  "<b>넷플연가</b>는 영화화 취향을 중심으로 하는 오프라인 커뮤니티 서비스입니다. 멤버십 기반으로 운영되며 사랑, 철학, 라이프스타일, 글쓰기 등의 다양한 주제로 서울의 다양한 공간에 모여서 이야기하고 어울리는 서비스입니다.",
  "비즈니스의 기획 및 운영 전반에 참여했으며, 서비스 전반의 디자인 및 개발을 담당했습니다. 또한, 초기 팀 빌딩과 서비스 운영의 기반을 만들고 데이터를 기반으로 사업이 성장할 수 있는 환경을 구축했습니다.",
  "<a href='https://netflix-salon.com/' target='_blank'>넷플연가 🔗</a>",
];

const SIDE_TEXTS_3 = [
  "문학 작품의 일부를 즉석으로 감열지에 인쇄하는 제품입니다. \n서울 국제 도서전에서 2017~2019년에 초청되어 전시되었으며 전국 약 20여 개의 지하철 역과 도서관 등에 설치되었습니다.",
  "제품의 기획 및 설계 전반을 담당하고, 초기 생산되는 제품의 임베디드 프로그래밍과 하드웨어 제작 전반을 진행했습니다.",
  "<a href='https://www.91-degree.com/' target='_blank'>문학 자판기 \"91도시\" 🔗</a>",
];

const startDate = new Date("2016-03-01");
const date = new Date();
const diffMonth = (date.getFullYear() - startDate.getFullYear()) * 12 + (date.getMonth() - startDate.getMonth());

const getCareerPeriod = (startDate, endDate) => {
  const diffMonth = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
  const years = Math.floor(diffMonth / 12);
  const months = diffMonth % 12;
  return `${years}년 ${months}개월`;
}

const ssmPeriod = getCareerPeriod(new Date("2016-03-01"), new Date("2018-10-01"));
const snowPeriod = getCareerPeriod(new Date("2018-10-01"), new Date("2020-07-01"));
const ridiCorpPeriod = getCareerPeriod(new Date("2020-08-01"), new Date());

const CAREER_PERIOD = getCareerPeriod(startDate, date);

const IndexPage = () => (
  <Layout>
    <Flex sx={styles.container}>
      <SEO title="이윤재" description="디지털 제품을 만듭니다." />
      <Box sx={styles.image}>
        <MainImage />
      </Box>
      <Text sx={styles.title}>이윤재</Text>
      <Text sx={styles.subTitle}>
        디지털 제품을 만듭니다.
        <br />
        <br />
        콘텐츠, 글로벌, 커뮤니티, 문화와 예술을 위한<br />제품과 서비스를 만들어왔습니다.
        {/* <br /> */}
        {/* 이를 위한 고객과 사용자 경험을 설계하고 코드로 표현하고 있습니다. */}
      </Text>
      <Box sx={styles.divider} />
      <Text id="career" sx={styles.title}>
        이력
      </Text>
      <Text sx={styles.subTitle}>
        창업으로 커리어를 시작해서 Software Engineer를 거쳐<br />Product Manager로 일하고 있습니다.
      </Text>
      <Flex sx={styles.itemWrapper}>
        <Flex>
          <Box sx={styles.appIcon} mr="16px">
            <MantaIcon />
          </Box>
          <Box sx={styles.appIcon}>
            <RidiIcon />
          </Box>
        </Flex>
        <Text fontWeight="bold" mt="40px">
          RIDI Corp.
        </Text>
        <Flex sx={styles.jobTitleWrapper}>
          <Text sx={styles.jobTitleText}>
            Manta Product Lead
          </Text>
          <Text sx={styles.dateText}>
            2022. 02 ~ 
          </Text>
        </Flex>
        <Flex >
          <Text sx={styles.jobTitleText}>
            리디 Product Manager
          </Text>
          <Text sx={styles.dateText}>
            2020. 08 ~ 2022. 01
          </Text>
        </Flex>
      </Flex>
      <Comment texts={RIDI_TEXTS} />
      <Flex sx={styles.itemWrapper}>
        <Box sx={styles.appIcon}>
          <SnowIcon />
        </Box>
        <Text fontWeight="bold" mt="40px">
          SNOW, Cake studio
        </Text>
        <Flex sx={styles.jobTitleWrapper}>
          <Text sx={styles.jobTitleText}>
            Software Engineer
          </Text>
          <Text sx={styles.dateText}>
            2018. 10 ~ 2020. 07
          </Text>
        </Flex>
      </Flex>
      <Comment texts={SNOW_TEXTS} />
      <Flex sx={styles.itemWrapper}>
        <Box sx={styles.appIcon}>
          <SsmIcon />
        </Box>
        <Text fontWeight="bold" mt="40px">
          10B
        </Text>
        <Flex sx={styles.jobTitleWrapper}>
          <Text sx={styles.jobTitleText}>
            CEO / co-founder
          </Text>
          <Text sx={styles.dateText}>
            2016. 03 ~ 2018. 10
          </Text>
        </Flex>
      </Flex>
      <Comment texts={SSM_TEXTS} />
      <Box sx={styles.divider} />
      <Text id="side-projects" sx={styles.title}>
        사이드 프로젝트
      </Text>
      <Text sx={styles.subTitle}>
        콘텐츠, 커뮤니티, 문화와 예술을 위한 서비스와 제품에 관심이 많습니다.
        <br />      
        많은 시도가 있었고 대부분은 실패했으며 몇몇 프로젝트는 성과를 거두었습니다.
      </Text>
      <Flex sx={styles.itemWrapper}>
        <Text mt="40px" fontWeight="bold">
          놐놐 - 스토리 있는 방구석 소개팅
        </Text>
        <Flex sx={styles.jobTitleWrapper}>
          <Text sx={styles.jobTitleText}>
          비즈니스 기획, 웹 기획 및 개발
          </Text>
          <Text sx={styles.dateText}>
            2021
          </Text>
        </Flex>
      </Flex>
      <Comment texts={SIDE_TEXTS_1} />
      <Flex sx={styles.itemWrapper}>
        <Text mt="50px" fontWeight="bold">
          넷플릭스 보는 날이면 연희동에 가야 한다
        </Text>
        <Flex sx={styles.jobTitleWrapper}>
          <Text sx={styles.jobTitleText}>
          비즈니스 기획, 웹 기획 및 개발
          </Text>
          <Text sx={styles.dateText}>
            2019
          </Text>
        </Flex>
      </Flex>
      <Comment texts={SIDE_TEXTS_2} />
      <Flex sx={styles.itemWrapper}>
        <Text mt="50px" fontWeight="bold">
          문학 자판기
        </Text>
        <Flex sx={styles.jobTitleWrapper}>
          <Text sx={styles.jobTitleText}>
            제품 설계, 하드웨어 개발
          </Text>
          <Text sx={styles.dateText}>
            2017
          </Text>
        </Flex>
      </Flex>
      <Comment texts={SIDE_TEXTS_3} />
      <Box sx={styles.divider} />
      <Text id="education" sx={styles.title}>
        학력
      </Text>
      <Flex sx={styles.itemWrapper}>
        <Text mt="20px" mb="20px" fontWeight="bold">
            UNIST
        </Text>
        <Text sx={styles.dateText} mb="16px">
          2010 ~ 2015, drop-out
        </Text>
        <Flex>
          <Text sx={styles.dateText} mr="10px">
            Major
          </Text>
          <Text sx={styles.jobTitleText}>
            Human & System Engineering
          </Text>
        </Flex>
        <Flex>
          <Text sx={styles.dateText} mr="10px">
            Minor
          </Text>
          <Text sx={styles.jobTitleText}>
            Computer Science
          </Text>
        </Flex>
        <Flex>
          <Text sx={styles.dateText} mr="10px">
            Minor
          </Text>
          <Text sx={styles.jobTitleText}>
            Industrial Design
          </Text>
        </Flex>
      </Flex>
      <Comment texts={EDU_TEXTS} />
      <Box sx={styles.divider} />
      <Text id="skills" sx={styles.title}>
        주요 스킬
      </Text>
      <Text sx={{...styles.subTitle, mb: '10px'}}>
        제품 개발 전반에 대한 이해와 경험이 있습니다.<br/>
        조직과 상황에 맞추어 적절한 방법론을 적용하고, 팀을 이끌어 나갑니다.
      </Text>
      <Flex sx={styles.itemWrapper}>
        <Text sx={styles.jobTitleText} mb="12px">
          Product Management
        </Text>
        <Text sx={styles.dateText}>
          Product strategy & Roadmap<br/>
          Data analysis & A/B testing<br/>
          Technical project management
        </Text>
        <Text sx={styles.jobTitleText} mb="12px" mt="24px">
          Software engineering
        </Text>
        <Text sx={styles.dateText}>
          React, React Native, iOS Native
          <br />
          Typescript, Swift & Objective-c
        </Text>
        <Text sx={styles.jobTitleText} mb="12px" mt="24px">
          UX
        </Text>
        <Text sx={styles.dateText}>
          UX design, Wireframe & Prototyping
          <br />
        </Text>
      </Flex>
      <Box sx={styles.divider} />
      <Text id="what-i-enjoy" sx={styles.title}>
        즐기는 것
      </Text>
      <Text sx={styles.subTitle}>
        📖 책과 텍스트 컨텐츠
        <br />
        🎞 영화와 넷플릭스
        <br />
        🎮 콘솔 게임
        <br />
        ☕️ 따뜻한 커피 🍕 페퍼로니 피자 🍜 평양냉면 🍻 맥주
      </Text>
      <Box sx={styles.divider} />
      <Text id="what-i-love" sx={styles.title}>
        좋아하는 것
      </Text>
      <Text sx={styles.subTitle}>
      🐈‍⬛ 고양이
          <br /> 🤩 아름다운 제품
          <br />
          👨‍🦳 도널드 노먼, 디터 람스
          <br />
          🌊 사이에 있는 것들, 쉽게 바뀌는 것들, 덧없이 사라지는 것들
          <br />
          🌕 변하지 않는 가치
      </Text>
      <Box sx={styles.divider} />
      <Flex sx={styles.itemWrapper} mb="68px">
        <Text sx={styles.dateText}>
          이야기는 언제나 환영입니다.<br />Feel free to reach out anytime :)
        </Text>
        <Flex mt="14px" alignItems="center">
          <Link href="https://www.linkedin.com/in/yunjae-lee-813505a9/" target="_blank"> 
            <Box sx={{ width: '30px', height: '30px', tintColor: '#383841', opacity: 0.5, p: "4px", mr: '8px'}}>
              <LinkedinIcon />
            </Box>
          </Link>
          <Link href="mailto:pax.lyj@gmail.com" target="_blank"> 
            <Box sx={{ width: '30px', height: '30px', tintColor: '#383841', opacity: 0.5}}>
              <EmailIcon />
            </Box>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  </Layout>
);

const styles = {
  container: {
    color: "#383841",
    fontFamily: "'Noto Sans KR', sans-serif",
    fontSize: ["18px", "24px"],
    flex: 1,
    pt: ["40px", "60px"],
    px: ["16px", "20px"],
    mx: "auto",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "720px",
    a: {
      color: "#007EDB",
      fontWeight: "bold",
    },
  },
  image: {
    tintColor: "#007EDB",
    height: ["200px", "300px"],
    width: ["200px", "300px"],
  },
  title: {
    mt: ["52px", "68px"],
    fontSize: ["34px", "40px"],
    fontWeight: "bold",
  },
  divider: {
    width: "100%",
    height: "2px",
    opacity: 0.1,
    bg: "#383841",
    mt: ["65px", "84px"],
  },
  subTitle: {
    mt: ["40px", "70px"],
    mb: ["24px", "40px"],
    fontSize: ["16px", "18px"],
    lineHeight: ["28px", "38px"],
    textAlign: "center",
    opacity: 0.9,
    fontWeight: "medium",
  },
  itemWrapper: {
    py: "30px",
    mt: ["25px", "40px"],
    alignItems: "center",
    flexDirection: "column",
  },
  appIcon: {
    width: 60,
    height: 60,
    borderRadius: "12px",
    border: '2px solid #E8E9ED',
    overflow: "hidden",
    boxShadow: "0px 6px 25px rgba(23, 25, 29, 0.02)",
  },
  jobTitleWrapper: {
    mt: ["14px", "18px"],
  },
  jobTitleText: {
    fontSize: ["16px", "18px"],
    fontWeight: "bold",
    mr: ["12px", "16px"],
    opacity: 0.85
  },
  dateText: {
    textAlign: 'center',
    fontSize: ["14px", "16px"],
    opacity: 0.5
  }
};

export default IndexPage;
