import React from "react";

export const nl2br = (text) => (
  <React.Fragment>
    {text.split(/\r\n|\r|\n/).map((l) => (
      <React.Fragment>
        {l.length > 0 ? (
          <div dangerouslySetInnerHTML={{ __html: l }} />
        ) : (
          <br />
        )}
      </React.Fragment>
    ))}
  </React.Fragment>
);
