// @flow

import React, { useState } from "react";

import { Button, Box, Flex, Text } from "rebass";
import Profile from "./profileImage";
import CommentImage from "./commentImage";
import Question from "./Question";
import { nl2br } from "../utils";

type Props = {
  initialState?: boolean,
  alwaysShow?: boolean,
  texts: string[],
  more: { tag: string, question: string, answer: string }[],
};

const Comment = ({ texts, more, initialState = false, alwaysShow = false }: Props) => {
  const [show, setShow] = useState(initialState);
  return (
    <>
      {show ? (
        <>
          <Flex pt="10px" width="100%">
            <Box sx={styles.profileWrapper}>
              <Profile />
            </Box>
            <Flex sx={styles.textWrapper}>
              {texts.map((text, index) => (
                <Box key={`ballon_${index}`} sx={{...styles.ballon, borderTopLeftRadius: index === 0 ? 0 : "13px", opacity: show ? 1 : 0, translateY: '100px' }}>
                  <Text>{nl2br(text)}</Text>
                </Box>
              ))}
              {more && more.length > 0 && (
                <Flex sx={styles.moreWrapper}>
                  {more.map((more) => (
                    <Question more={more} />
                  ))}
                </Flex>
              )}
            </Flex>
          </Flex>
          {!alwaysShow && <Box mx="auto" mt="15px">
            <Button sx={styles.button} onClick={() => setShow(false)}>
              <Text fontSize={["14px", "16px"]} fontWeight="bold">
                닫기
              </Text>
            </Button>
          </Box>}
        </>
      ) : (
        <Button sx={styles.button} onClick={() => setShow(true)}>
          <Flex alignItems="center">
            <Box width="17px">
              <CommentImage />
            </Box>
            <Text fontSize={["14px", "16px"]} fontWeight="bold" ml="12px">
              코멘트
            </Text>
          </Flex>
        </Button>
      )}
    </>
  );
};

const styles = {
  button: {
    cursor: "pointer",
    flexDirection: "row",
    height: "48px",
    px: "16px",
    py: "0px",
    borderRadius: "8px",
    bg: "#f6f5f2",
    alignItems: "center",
    color: "#665C5C",
    flexWrap: "wrap",
    transition: "all 0.25s ease 0s",
    ":hover": {
      opacity: 0.8,
    },
    ":focus": {
      outline: "none",
      boxShadow: "none",
    },
    ":active": {
      opacity: 1,
      bg: "#f6f5f2",
    },
  },
  profileWrapper: {
    mt: ["0px", "-10px"],
    borderRadius: "50%",
    overflow: "hidden",
    border: '2px solid #E8E9ED',
    width: ["40px", "60px"],
    height: ["40px", "60px"],
    // boxShadow: "0px 3px 16px rgba(23, 25, 29, 0.05)",
  },
  textWrapper: {
    flexDirection: "column",
    width: "100%",
    flex: 1,
  },
  ballon: {
    ml: "15px",
    mr: "auto",
    mt: "15px",
    px: ["18px", "20px"],
    py: ["15px", "16px"],
    borderRadius: "0 13px 13px 13px",
    bg: "#f6f5f2",
    color: '#665C5C',
    flexWrap: "wrap",
    fontSize: ["14px", "16px"],
    lineHeight: ["20px", "26px"],
    wordBreak: "keep-all",
    transition: "all 0.25s ease 0s",
  },
  moreWrapper: {
    flexDirection: "column",
    ml: "auto",
    alignItems: "right",
    flexWrap: "wrap",
  },
};

export default React.memo<Props>(Comment);
