// @flow

import React, { useState, useCallback } from "react";

import { Button, Box, Flex, Text } from "rebass";
import Profile from "./profileImage";
import CommentImage from "./commentImage";
import { nl2br } from "../utils";

type Props = {
  more: { tag: string, question: string, answer: string },
};

const Question = ({ more }: Props) => {
  const [show, setShow] = useState(false);
  const onClickQuestion = useCallback(() => {
    setShow(true);
  }, []);
  return (
    <Flex sx={styles.wrapper}>
      <Flex sx={styles.questions} onClick={onClickQuestion}>
        <Text>{more.tag}</Text>
      </Flex>
      {show && (
        <Box sx={styles.ballon}>
          <Text>{nl2br(more.answer)}</Text>
        </Box>
      )}
    </Flex>
  );
};

const styles = {
  wrapper: {
    flexDirection: "column",
  },
  ballon: {
    ml: "15px",
    mr: "auto",
    mt: "15px",
    px: ["18px", "34px"],
    py: ["15px", "26px"],
    borderRadius: "0 26px 26px 26px",
    bg: "#E8E9ED",
    fontSize: ["14px", "16px"],
    lineHeight: ["20px", "26px"],
    wordBreak: "keep-all",
  },
  questions: {
    ml: "auto",
    cursor: "pointer",
    height: "54px",
    bg: "#3F414B",
    px: "14px",
    textDecoration: "underline",
    mt: "15px",
    opacity: 0.9,
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "27px",
    fontSize: ["16px", "18px"],
    color: "#fff",
    transition: "all 0.25s ease 0s",
    ":hover": {
      opacity: 0.8,
    },
    ":focus": {
      outline: "none",
      boxShadow: "none",
    },
    ":active": {
      opacity: 1,
    },
  },
};

export default React.memo<Props>(Question);
